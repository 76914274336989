import Navbar from '@cohort/wallet/components/navigation/NavBar';
import StoreDropdownNavigation from '@cohort/wallet/components/store/StoreDropdownNavigation';
import {useAppStore} from '@cohort/wallet/hooks/stores/app';
import {useMerchantContext} from '@cohort/wallet/hooks/useMerchantContext';
import {useNavigateWithTransition} from '@cohort/wallet/hooks/useNavigateWithTransition';
import useStoreContext from '@cohort/wallet/hooks/useStoreContext';
import useThemeContext from '@cohort/wallet/hooks/useThemeContext';
import BaseLayout from '@cohort/wallet/layouts/BaseLayout';
import {setAmplitudeMetadata} from '@cohort/wallet/lib/Tracking';
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';

const StoreLayout = (): JSX.Element => {
  const {t} = useTranslation('layouts', {
    keyPrefix: 'store.storeLayout',
  });
  const {campaign} = useStoreContext();
  const merchant = useMerchantContext();
  const {setCampaignTheme} = useThemeContext();
  const navigateWithTransition = useNavigateWithTransition();
  const withNavbar = useAppStore(store => store.withNavbar);

  useEffect(() => {
    setCampaignTheme(campaign);
  }, [campaign, setCampaignTheme]);

  useEffect(() => {
    setAmplitudeMetadata({merchant, campaign, rootPath: 'store'});
  }, [campaign, merchant]);

  const navbar = withNavbar ? (
    <Navbar
      storeSlug={campaign.store.slug}
      handleBackButtonClick={() => {
        navigateWithTransition(-1, undefined, 'backward');
      }}
      logoFileKey={merchant.logoFileKey}
      appTitle={t('experienceStoreTitle', {name: merchant.name})}
      useBurgerNavigation={false}
    >
      <div className="flex h-full w-full items-center justify-end">
        <StoreDropdownNavigation />
      </div>
    </Navbar>
  ) : (
    <div className="flex w-full items-center justify-end pr-4 pt-2 md:pr-8">
      <StoreDropdownNavigation />
    </div>
  );
  return <BaseLayout navbar={navbar} />;
};

export default StoreLayout;
