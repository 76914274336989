import {cn} from '@cohort/shared-frontend/utils/classNames';
import type {BaseButtonProps, BtnTextTransform} from '@cohort/wallet/components/button/BaseButton';
import {BaseButton} from '@cohort/wallet/components/button/BaseButton';

type SecondaryButtonProps = BaseButtonProps;

const SecondaryButton: React.FC<SecondaryButtonProps> = ({className, ...props}): JSX.Element => (
  <BaseButton
    className={cn(
      className,
      'bg-[--xps-secondary-btn-background-color]',
      'hover:bg-[--xps-secondary-btn-hover-background-color] hover:text-[--xps-secondary-btn-hover-color]',
      'border-[--xps-secondary-btn-border-color]',
      'text-[--xps-secondary-btn-color]'
    )}
    style={{
      borderRadius:
        props.size === 'small'
          ? 'calc(var(--xps-secondary-btn-border-radius) * 0.75)'
          : 'var(--xps-secondary-btn-border-radius)',
      textTransform: 'var(--xps-secondary-btn-text-transform)' as BtnTextTransform,
    }}
    {...props}
  />
);

export default SecondaryButton;
