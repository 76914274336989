import {useAppStore} from '@cohort/wallet/hooks/stores/app';
import sendMessageToParent from '@cohort/wallet/lib/WindowMessaging';
import {useCallback} from 'react';
import {shallow} from 'zustand/shallow';

export function useRedirectToCustomLogin(): {
  redirectCustomLoginEnabled: boolean;
  redirectToCustomLogin: (cohortRedirect?: string) => null;
} {
  const {customLoginUrl, customLoginRedirectParameterName, embedded, embedUrl} = useAppStore(
    store => ({
      customLoginUrl: store.customLoginUrl,
      customLoginRedirectParameterName: store.customLoginRedirectParameterName,
      embedded: store.embedded,
      embedUrl: store.embedUrl,
    }),
    shallow
  );
  const redirectCustomLoginEnabled = !!(customLoginUrl && embedded && embedUrl);

  const redirectToCustomLogin = useCallback(
    (cohortRedirect?: string) => {
      if (!redirectCustomLoginEnabled) {
        return null;
      }
      const redirectUrl = new URL(embedUrl);

      // Support both relative and absolute custom login URLs
      // In particular, relative URLs are used by Shopify
      const loginUrl =
        customLoginUrl.startsWith('http://') || customLoginUrl.startsWith('https://')
          ? new URL(customLoginUrl)
          : new URL(`${redirectUrl.origin}${customLoginUrl}`);
      const redirectParameter = customLoginRedirectParameterName ?? 'destination';

      if (cohortRedirect !== undefined) {
        redirectUrl.searchParams.set('cohortRedirect', cohortRedirect);
      }
      loginUrl.searchParams.set(redirectParameter, redirectUrl.toString());
      sendMessageToParent({
        event: 'auth.redirect',
        payload: {
          url: loginUrl.toString(),
        },
      });
      return null;
    },
    [customLoginUrl, customLoginRedirectParameterName, embedUrl]
  );

  return {
    redirectCustomLoginEnabled,
    redirectToCustomLogin,
  };
}
