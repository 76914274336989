const StoreController = {
  protectedRoutes: [
    {
      path: 'checkout',
      lazy: () => import('@cohort/wallet/pages/store/CheckoutPage'),
    },
    {
      path: 'orders/:orderId',
      lazy: () => import('@cohort/wallet/pages/store/OrderPage'),
    },
  ],
  publicRoutes: [
    {
      path: 'login',
      lazy: () => import('@cohort/wallet/pages/store/StoreLoginPage'),
    },
  ],
};

export default StoreController;
