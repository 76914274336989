import useClickTracker from '@cohort/wallet/hooks/clickTracker';
import type {TrackingConfig} from '@cohort/wallet/lib/Tracking';
import React from 'react';

export type TrackableButtonProps = JSX.IntrinsicElements['button'] & {
  tracking: TrackingConfig;
};

export const TrackableButton: React.FC<TrackableButtonProps> = ({
  tracking,
  children,
  ...props
}): React.ReactElement => {
  const trackingCallback = useClickTracker(tracking.namespace, tracking.metadata);

  return (
    <button
      onClick={e => {
        trackingCallback();
        props.onClick?.(e);
      }}
      {...props}
    >
      {children}
    </button>
  );
};
