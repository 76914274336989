import {cn} from '@cohort/shared-frontend/utils/classNames';
import type {BaseButtonProps, BtnTextTransform} from '@cohort/wallet/components/button/BaseButton';
import {BaseButton} from '@cohort/wallet/components/button/BaseButton';

type PrimaryBtnProps = BaseButtonProps;

const PrimaryButton: React.FC<PrimaryBtnProps> = ({className, ...props}): JSX.Element => (
  <BaseButton
    className={cn(
      className,
      'bg-[--xps-primary-btn-background-color]',
      'hover:bg-[--xps-primary-btn-hover-background-color] hover:text-[--xps-primary-btn-hover-color]',
      'border-[--xps-primary-btn-border-color]',
      'text-[--xps-primary-btn-color] hover:text-[--xps-primary-btn-hover-color]'
    )}
    style={{
      borderRadius:
        props.size === 'small'
          ? 'calc(var(--xps-primary-btn-border-radius) * 0.75)'
          : 'var(--xps-primary-btn-border-radius)',
      textTransform: 'var(--xps-primary-btn-text-transform)' as BtnTextTransform,
    }}
    {...props}
  />
);

export default PrimaryButton;
