import App from '@cohort/wallet/App';
import NotFoundPage from '@cohort/wallet/components/error-pages/NotFoundPage';
import NotFoundSection from '@cohort/wallet/components/error-pages/NotFoundSection';
import ChallengeContextProvider from '@cohort/wallet/components/providers/ChallengeContextProvider';
import MerchantContextProvider from '@cohort/wallet/components/providers/MerchantContextProvider';
import StoreContextProvider from '@cohort/wallet/components/providers/StoreContextProvider';
import {ThemeContextProvider} from '@cohort/wallet/components/providers/ThemeContextProvider';
import ChallengeLayout from '@cohort/wallet/layouts/challenges/ChallengeLayout';
import SpaceLayout from '@cohort/wallet/layouts/space/SpaceLayout';
import StoreLayout from '@cohort/wallet/layouts/store/StoreLayout';
import {
  getChallengeDetailRoute,
  getChallengeLoginRoute,
  getMerchantSpaceLoginRoute,
  getStoreCheckoutRoute,
  getStoreLoginRoute,
} from '@cohort/wallet/lib/Pages';
import CampaignSlugRoutes from '@cohort/wallet/router/CampaignSlugRoutes';
import ChallengeController from '@cohort/wallet/router/ChallengeController';
import ChallengeSlugRoutes from '@cohort/wallet/router/ChallengeSlugRoutes';
import ProtectedRoutes from '@cohort/wallet/router/ProtectedRoutes';
import PublicRoutes from '@cohort/wallet/router/PublicRoutes';
import SpaceController from '@cohort/wallet/router/SpaceController';
import StoreController from '@cohort/wallet/router/StoreController';
import {wrapCreateBrowserRouter} from '@sentry/react';
import {createBrowserRouter, Navigate} from 'react-router-dom';

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);

const routes = sentryCreateBrowserRouter([
  {
    path: '/',
    element: (
      <MerchantContextProvider>
        <ThemeContextProvider>
          <App />
        </ThemeContextProvider>
      </MerchantContextProvider>
    ),
    children: [
      {index: true, element: <Navigate to="/space/home" replace />},
      {
        path: 'space/*',
        element: <SpaceLayout />,
        children: [
          {
            element: <PublicRoutes defaultRedirectPath="/space/home" />,
            children: SpaceController.publicRoutes,
          },
          {
            element: <ProtectedRoutes loginPath={getMerchantSpaceLoginRoute()} />,
            children: SpaceController.protectedRoutes,
          },
          // Is both public and protected so we keep it outside
          ...SpaceController.bothPublicAndProtectedRoutes,
          {
            path: '*',
            element: <NotFoundSection />,
          },
        ],
      },
      {
        path: 'store/:campaignSlug/*',
        element: (
          <CampaignSlugRoutes>
            {campaignSlug => (
              <StoreContextProvider campaignSlug={campaignSlug}>
                <StoreLayout />
              </StoreContextProvider>
            )}
          </CampaignSlugRoutes>
        ),
        children: [
          {
            index: true,
            lazy: () => import('@cohort/wallet/pages/store/StoreCampaignPage'),
          },
          {
            element: (
              <CampaignSlugRoutes>
                {campaignSlug => (
                  // navigate to the checkout page once login is completed
                  <PublicRoutes defaultRedirectPath={getStoreCheckoutRoute(campaignSlug)} />
                )}
              </CampaignSlugRoutes>
            ),
            children: StoreController.publicRoutes,
          },
          {
            element: (
              <CampaignSlugRoutes>
                {campaignSlug => <ProtectedRoutes loginPath={getStoreLoginRoute(campaignSlug)} />}
              </CampaignSlugRoutes>
            ),
            children: StoreController.protectedRoutes,
          },
          {
            path: '*',
            element: <NotFoundSection />,
          },
        ],
      },
      {
        path: 'challenges/:challengeSlug/*',
        element: (
          <ChallengeContextProvider>
            <ChallengeLayout />
          </ChallengeContextProvider>
        ),
        children: [
          {
            index: true,
            lazy: () => import('@cohort/wallet/pages/challenges/ChallengeDetailPage'),
          },
          {
            element: (
              <ChallengeSlugRoutes>
                {challengeSlug => (
                  <PublicRoutes
                    // the campaign should be automaticaly joined once login is completed
                    defaultRedirectPath={`${getChallengeDetailRoute(
                      challengeSlug
                    )}?action=joinCampaign`}
                  />
                )}
              </ChallengeSlugRoutes>
            ),
            children: ChallengeController.publicRoutes,
          },
          {
            element: (
              <ChallengeSlugRoutes>
                {challengeSlug => (
                  <ProtectedRoutes loginPath={getChallengeLoginRoute(challengeSlug)} />
                )}
              </ChallengeSlugRoutes>
            ),
            children: ChallengeController.protectedRoutes,
          },
          {
            path: '*',
            element: <NotFoundSection />,
          },
        ],
      },
      // make the next line only in development
      ...(import.meta.env.COHORT_ENV === 'development'
        ? [
            {
              path: '/sentry',
              lazy: () => import('@cohort/wallet/pages/SentryTestPage'),
            },
          ]
        : []),
      {
        path: '*',
        element: <NotFoundPage />,
      },
    ],
  },
]);

export default routes;
