import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@cohort/shared-frontend/components/Dropdown';
import {cn} from '@cohort/shared-frontend/utils/classNames';
import {TrackableButton} from '@cohort/wallet/components/tracking/TrackableButton';
import useHandleLoginNavigation from '@cohort/wallet/hooks/handleLoginNavigation';
import useNotify from '@cohort/wallet/hooks/notify';
import {useAppStore} from '@cohort/wallet/hooks/stores/app';
import {useUserStore} from '@cohort/wallet/hooks/stores/user';
import {useNavigateWithTransition} from '@cohort/wallet/hooks/useNavigateWithTransition';
import useStoreContext from '@cohort/wallet/hooks/useStoreContext';
import useThemeContext from '@cohort/wallet/hooks/useThemeContext';
import {logoutUser} from '@cohort/wallet/lib/Authentication';
import {getMerchantSpaceRoute, getStoreLoginRoute} from '@cohort/wallet/lib/Pages';
import type {UserWDto} from '@cohort/wallet-schemas/user';
import {HouseLine, Question, SignIn, UserCircle} from '@phosphor-icons/react';
import React from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useLocation} from 'react-router-dom';

const SUPPORT_EMAIL = 'support@getcohort.com';

const StoreDropdownTrigger: React.FC = () => (
  <DropdownMenuTrigger>
    <TrackableButton
      tracking={{namespace: 'navbar.profile.toggle'}}
      className="relative inline-flex cursor-pointer items-center rounded-md border-transparent py-2 text-sm font-medium text-[--xps-navbar-link-color] hover:bg-[--xps-navbar-link-active-background-color] hover:text-[--xps-color]"
    >
      <div className="flex items-center space-x-1 px-3">
        <UserCircle className="h-5 w-5" aria-hidden="true" />
      </div>
    </TrackableButton>
  </DropdownMenuTrigger>
);

type DropdownUserSectionProps = {
  user: UserWDto;
};

const StoreDropdownUserSection: React.FC<DropdownUserSectionProps> = ({user}) => {
  const {t} = useTranslation('components', {keyPrefix: 'store.storeDropdownNavigation'});

  return (
    <DropdownMenuGroup className="border-b p-4">
      <p>{t('signedAs')}</p>
      <p className="truncate font-medium text-[--xps-color]">{user.email}</p>
    </DropdownMenuGroup>
  );
};

const StoreDropdownButtonsSection: React.FC = () => {
  const {t} = useTranslation('components', {keyPrefix: 'store.storeDropdownNavigation'});
  const {search} = useLocation();
  const {campaign} = useStoreContext();
  const {hasDarkBg} = useThemeContext();
  const disableLogout = useAppStore(store => store.disableLogout);
  const notify = useNotify();
  const navigateWithTransition = useNavigateWithTransition();

  const iconClassNames = cn('mr-2 h-5 w-5', hasDarkBg ? 'text-white/30' : 'text-black/30');

  return (
    <DropdownMenuGroup className="my-2">
      <DropdownMenuItem>
        <TrackableButton
          tracking={{
            namespace: 'navbar.profile.visitSpace',
          }}
          className="flex items-center"
          onClick={() => navigateWithTransition(getMerchantSpaceRoute())}
        >
          <HouseLine size={20} className={iconClassNames} aria-hidden="true" />
          <p>{t('myNFTSpace')}</p>
        </TrackableButton>
      </DropdownMenuItem>
      <DropdownMenuItem>
        <TrackableButton
          className="flex items-center"
          onClick={() =>
            notify(
              'info',
              <Trans
                t={t}
                components={{
                  contact: (
                    <a
                      className="font-medium underline"
                      href={`mailto:${SUPPORT_EMAIL}`}
                      target="_blank"
                      rel="noreferrer"
                    />
                  ),
                }}
              >
                {t('helpContent', {email: SUPPORT_EMAIL})}
              </Trans>,
              {autoClose: false}
            )
          }
          tracking={{
            namespace: 'navbar.profile.showHelpModal',
          }}
        >
          <Question size={20} className={iconClassNames} aria-hidden="true" />
          <p>{t('help')}</p>
        </TrackableButton>
      </DropdownMenuItem>
      {!disableLogout && (
        <DropdownMenuItem>
          <TrackableButton
            className="flex items-center"
            onClick={async () => {
              await logoutUser();
              navigateWithTransition({
                pathname: getStoreLoginRoute(campaign.store.slug),
                search,
              });
            }}
            tracking={{
              namespace: 'navbar.profile.logout',
            }}
          >
            <SignIn size={20} className={iconClassNames} aria-hidden="true" />
            <p>{t('logout')}</p>
          </TrackableButton>
        </DropdownMenuItem>
      )}
    </DropdownMenuGroup>
  );
};

const StoreDropdownNavigation: React.FC = () => {
  const {t} = useTranslation('components', {keyPrefix: 'store.storeDropdownNavigation'});
  const user = useUserStore(store => store.user);
  const navigateToLogin = useHandleLoginNavigation();
  const {backgroundColor} = useThemeContext();

  if (!user) {
    return (
      <TrackableButton
        tracking={{namespace: 'navbar.profile.login'}}
        onClick={() => navigateToLogin()}
        className="relative inline-flex cursor-pointer items-center rounded-md border-transparent px-4 py-2 text-sm font-medium text-[--xps-navbar-link-color] hover:bg-[--xps-navbar-link-active-background-color]"
      >
        <SignIn className="ml-0 mr-0 h-5 w-5 sm:-ml-1 sm:mr-2" aria-hidden="true" />
        <span className="hidden sm:inline">{t('signIn')}</span>
      </TrackableButton>
    );
  }

  return (
    <DropdownMenu>
      <StoreDropdownTrigger />
      <DropdownMenuContent style={{backgroundColor}} align="end">
        <StoreDropdownUserSection user={user} />
        <StoreDropdownButtonsSection />
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
export default StoreDropdownNavigation;
